import { useNavigateTo } from '../../hooks/useNavigateTo'
import styles from "./footer.module.css"
import React from "react";
const Footer = () => {
  const navigateTo = useNavigateTo()
  return (
    <>
      <div className={styles.container}>
        <div className={styles.column}>
          <h2>УСЛУГИ</h2>
          <p className={styles.clickable} onClick={() => navigateTo('/services/software')}>Разработка ПО</p>
          <p className={styles.clickable} onClick={() => navigateTo('/services/mobile-apps')}>Разработка мобильных приложений</p>
          <p className={styles.clickable} onClick={() => navigateTo('/services/web-services')}>Разработка веб-сервисов</p>
        </div>
        <div className={styles.column}>
          <h2>ПРОДУКТЫ</h2>
          <p>Крипт.Клауд</p>
          <p className={styles.clickable} onClick={() => {navigateTo('/products/offergen')}}>КП-генератор</p>
        </div>
        <div className={styles.column}>
          <h2>КОНТАКТЫ</h2>
          <p>+7 (995) 668-35-84</p>
          <p>info@вр-ит.рф</p>
        </div>
        <div className={styles.icons}>
          <img
            src='/Pictures/tg-icon-50.svg' onClick={() => {
            window.open('https://t.me/ooo_vebrazrabotka')
          }} />
          <img
            src='/Pictures/vk-icon-50.svg' onClick={() => {
            window.open('https://vk.com/vr_it_ru')
          }} />
        </div>
      </div>
      <div className={styles.copyright}>
        <p>© «ВР» 2019-2025</p>
        <p>ОГРН: 1195958023392</p>
      </div>
    </>
  )
}
export default Footer